:root {
  --schlechtriem-gruen: #008a84;
}

.berechnungs__tabelle {
  margin: 0 auto;
  max-width: 960px;
}

.tabelle__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0 10px 0;
  flex-wrap: wrap;
  justify-content: space-between;

  label {
    margin: 5px 0;
  }

  input {
    margin-left: 10px;
  }
}

.rt-td {
  text-align: center;
}

.pdf__viewer iframe {
  width: 100%;
  height: 30vh;
}

.formular__container {
  margin-top: 25px;
}

.formular__inner {
  display: flex;

  .formular__inner--left {
    display: flex;
    flex-direction: column;
  }

  p {
    margin: 0;
  }

  .formular__inner--left {
    div>span {
      margin: 10px 0;
    }

    div {
      display: flex;
      justify-content: space-between;
    }
  }
}

.tabellen__tool input,
#tabellen__tool input {
  background: var(--schlechtriem-gruen);
  color: white;
  font-size: 16px;
  max-width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 2px;
  text-align: right;
  box-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.2);
}